import { css } from '@emotion/core'
import styled from '@emotion/styled'
import React, { useEffect } from 'react'

import {
  LeshenPhoneCTA,
  PackageCard,
  useUser,
} from '@leshen/gatsby-theme-leshen'
import {
  Columns,
  LinkButton,
  Stack,
  Typography,
  VariableContent,
  scrollTo,
} from '@leshen/ui'

import { navigate } from 'gatsby'

import useAvailablePlans from '../hooks/useAvailablePlans'
import useHasMounted from '../hooks/useHasMounted'

import zipLogic from '../utils/zipLogic'

import Spinner from './Spinner'

const ZipPackageSection = () => {
  const { userData, userLocation, setUserData } = useUser()

  // Store the userData in localStorage for persistance.
  const { availablePlans, setAvailablePlans } = useAvailablePlans(userData)

  /**
   * Check that userData is not null, which is it's initial state.
   * userData being set means it's been called at least once, and
   * the loading variable shows that it's currently loading.
   *
   * Important Note: We cannot just check for userData.loading
   * because that alone coming back false does not mean we want
   * to show the loading state. On initial page load, userData should
   * be null and we don't want to show a loading state then.
   *
   * This should be reworked in a big way, as there is a little too much
   * indirection for my taste, but there are a lot of moving parts that
   * aren't all accessible from this codebase so it will take some work.
   */
  const arePackagesLoading = userData && userData.loading

  useEffect(() => {
    /**
     * Commonly on page reload, `userData` will be null, and
     * we don't want to override localStorage with that data.
     * This does assume that `userData` will never be wiped
     * on purpose with the intent to wipe localStorage.
     * `null` is meant to be a starting point, and `userData`
     * should just be set to an empty object if the intent is
     * to clear `availablePlans`.
     */

    // this statement checks if maxmind found a zipcode and runs zip logic if so
    if (userLocation?.zipCode?.length === 5 && !userData) {
      ;(async () => {
        const packageData = await zipLogic(userLocation.zipCode)

        setUserData({
          loading: false,
          latinoCenturylinkPackages: packageData,
        })
      })()
    }

    if (!userData && availablePlans) {
      return
    }
    setAvailablePlans(userData)
  }, [userData, setUserData, availablePlans, setAvailablePlans, userLocation])

  /**
   * This should be handled in the callback for the availability
   * lookup, but because that is handled through shadowing, and not
   * through passing down props from here, it is not feasible. I
   * decided it would be best to have the scrollTo and it's target
   * in the same file after considering all this.
   */
  useEffect(() => {
    if (
      availablePlans &&
      availablePlans?.latinoCenturylinkPackages?.Brightspeed_Market !== 'Y' &&
      (window.location.pathname === '/gp' ||
        window.location.pathname === '/gp/')
    ) {
      scrollTo(`#loadingScrollTarget`)
    } else if (
      availablePlans &&
      availablePlans?.latinoCenturylinkPackages?.Brightspeed_Market === 'Y' &&
      window.location.pathname !== '/disponibilidad/bsp' &&
      window.location.pathname !== '/disponibilidad/bsp/'
    ) {
      navigate('/disponibilidad/bsp')
    } else if (
      availablePlans &&
      availablePlans?.latinoCenturylinkPackages?.Brightspeed_Market !== 'Y' &&
      window.location.pathname !== '/disponibilidad' &&
      window.location.pathname !== '/disponibilidad/'
    ) {
      navigate('/disponibilidad')
    } else if (availablePlans) {
      scrollTo(`#loadingScrollTarget`)
    }
  }, [availablePlans])

  /**
   * Server-side rendering check to not have mismatching data
   * on the server, which causes a bad rehydration to layout
   * content incorrectly in some situations when using local storage.
   */
  const hasMounted = useHasMounted()
  if (!hasMounted) {
    return null
  }

  return (
    <>
      {arePackagesLoading && (
        <VariableContent
          className="packages"
          alignMainContent="center"
          mainContent={
            <>
              <Typography variant="h2">
                Búsqueda de paquetes en su área.
              </Typography>
              <Spinner />
            </>
          }
        />
      )}

      {/* Set up the section(s) that show the users packages or out of area message below the Hero */}
      {availablePlans?.latinoCenturylinkPackages?.packages?.length >= 1 &&
        !arePackagesLoading &&
        availablePlans?.latinoCenturylinkPackages?.Brightspeed_Market !==
          'Y' && (
          <VariableContent
            className="packages"
            alignMainContent="center"
            mainContent={
              <div>
                <Typography variant="h2">
                  ¡Buenas Noticias! El Internet de CenturyLink está disponible
                  en tu área. Ve los paquetes de internet disponibles abajo.
                </Typography>
                <Typography variant="h5">
                  Internet de CenturyLink económico
                </Typography>
                {userLocation.city && userLocation.zipCode && (
                  <Typography>
                    <strong>
                      {availablePlans.latinoCenturylinkPackages.packages
                        .length === 1
                        ? 'El mejor paquete que encontraras en'
                        : 'Los mejores paquetes que encontraras en'}{' '}
                      {userLocation.city}, {/* eslint-disable-next-line */}
                      {userLocation.zipCode}{' '}
                      <StyledButtonLink
                        type="button"
                        onClick={() => scrollTo('h1')}
                      >
                        cambio
                      </StyledButtonLink>
                    </strong>
                  </Typography>
                )}
              </div>
            }
          >
            <Stack spacing="xxl">
              <Columns>
                {availablePlans.latinoCenturylinkPackages.packages.map(
                  (data) => (
                    <PackageCard
                      label={data.label}
                      packageData={{
                        ...data.brandy,
                      }}
                      content={
                        // Disabled lint line due to Gatsby api named variable
                        // eslint-disable-next-line no-underscore-dangle
                        <LeshenPhoneCTA color="primary">Llama</LeshenPhoneCTA>
                      }
                      key={data.label}
                      modal={
                        <span>
                          <a href="/terminos">Detalles de la oferta</a>
                        </span>
                      }
                    />
                  )
                )}
              </Columns>
            </Stack>
          </VariableContent>
        )}

      {availablePlans?.latinoCenturylinkPackages?.packages?.length === 0 &&
        !arePackagesLoading && (
          <VariableContent
            alignMainContent="center"
            mainContent={
              <div>
                <Typography variant="h2">
                  Por el momento, CenturyLink no está disponible en tu área.
                </Typography>
                {userLocation.city && userLocation.zipCode && (
                  <Typography>
                    <strong>
                      No se encontraron planes en {userLocation.zipCode},{' '}
                      <StyledButtonLink
                        type="button"
                        onClick={() => scrollTo('h1')}
                      >
                        cambio
                      </StyledButtonLink>
                    </strong>
                  </Typography>
                )}
                {availablePlans.centurylinkService && (
                  <Typography>
                    The good news is that we did find other internet plans
                    available in your area.
                  </Typography>
                )}
                <StyledButtonsContainer>
                  {availablePlans.centurylinkService && (
                    <LinkButton
                      variant="feature"
                      className="view-plans-button"
                      to={`https://centurylinkquote.com/?getqfiberZip=${userLocation.zipCode}`}
                    >
                      View Plans
                    </LinkButton>
                  )}
                </StyledButtonsContainer>
              </div>
            }
          />
        )}

      {/**
       * If userData is not null, and its `loading` property is set to false
       * by availabilitySubmit, then a fetch request has just completed.
       * That in combination with having no beam package data should mean
       * that there are no packages for the location the user searched.
       */}
      {availablePlans &&
        !availablePlans.loading &&
        availablePlans?.latinoCenturylinkPackages === null && (
          <VariableContent
            mainContent={
              <>
                <Typography variant="h2">
                  {`We're having trouble locating service options for your area.`}
                </Typography>
                <p>
                  Give us a call and one of our Internet specialists can help
                  get you connected!
                </p>
              </>
            }
            alignMainContent="center"
          />
        )}
    </>
  )
}

export default ZipPackageSection

const StyledButtonLink = styled.button(
  ({ theme }) => css`
    color: ${theme.colors.primary.base.hex};
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
    display: inline;
    font-weight: bold;
  `
)

const StyledButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .view-plans-button {
    margin-right: 16px;
  }
`
